.gallery .container {
  background: white;
  height: 100vh;
  display: grid;
  grid-template-columns: 17em auto;
}
.gallery .container .bar {
  height: 100%;
  background: linear-gradient(26deg, #0a4a75, #367ba7);
}
.gallery .container .bar .barContent {
  position: fixed;
  padding: 2em;
  color: #fff;
}
.gallery .container .bar .barContent img {
  width: 9em;
  margin: -2.5em 0 -1em 0;
}
.gallery .container .bar .barContent h2 {
  margin-bottom: 20px;
}
.gallery .container .bar .barContent .ourWork {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2em;
}
.gallery .container .bar .barContent .ourWork a {
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  background: linear-gradient(26deg, var(--t-color), var(--s-color));
}
.gallery .container .bar .barContent .ourWork a:hover {
  background: linear-gradient(26deg, #fbda02, #feaa0f);
}
.gallery .container .content {
  padding: 3em 2em;
  position: relative;
}
.gallery .container .content .title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.gallery .container .content .title h2 {
  position: relative;
  margin-bottom: 1.2em;
}
.gallery .container .content .title h2::after {
  content: "";
  background: linear-gradient(26deg, var(--t-color), var(--s-color));
  width: 42%;
  height: 4px;
  border-radius: 5px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}
.gallery .container .content .title p {
  max-width: 600px;
}
.gallery .container .content .examples {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin-top: 5em;
}
.gallery .container .content .examples img {
  width: 22em;
  transition: var(--transition);
  border: 1.5px solid black;
  border-radius: 7px;
}
.gallery .container .content .examples img:hover {
  transform: scale(1.03);
}
.gallery .container .content .more {
  margin-top: 5em;
}
.gallery .container .content .more .imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  margin-top: 3em;
}
.gallery .container .content .more .imgs img {
  width: 40%;
  border: 1.5px solid black;
  border-radius: 7px;
  transition: var(--transition);
}
.gallery .container .content .more .imgs img:hover {
  transform: scale(1.03);
}
.gallery .container .content video {
  margin-top: 4em;
  width: 100%;
  border: 1.5px solid black;
  border-radius: 7px;
}
.gallery .container .content .note {
  text-align: center;
  margin-top: 6em;
}
.gallery .container .content .note i {
  position: relative;
  font-size: 55px;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 4px solid black;
}
.gallery .container .content .note i::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.gallery .container .content .note p {
  font-size: 20px;
  margin-top: 10px;
}

@media (max-width: 1100px) {
  .gallery .container .content .examples {
    margin-top: 7em;
  }
  .gallery .container .content .examples img {
    width: 19em;
  }
}
@media (max-width: 900px) {
  .gallery .container .content .more .imgs img {
    width: 55%;
  }
}
@media (max-width: 700px) {
  .gallery .container {
    display: block;
  }
  .gallery .container .bar {
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2em;
  }
  .gallery .container .bar .barContent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5em;
  }
  .gallery .container .bar .barContent img {
    margin: 0;
  }
  .gallery .container .bar .barContent .ourWork {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .gallery .container .content .title h2 {
    font-size: 21px;
  }
  .gallery .container .content .more h2 {
    font-size: 21px;
    text-align: center;
  }
  .gallery .container .content .more .imgs img {
    width: 80%;
  }
}/*# sourceMappingURL=work.css.map */