


.landing {
    position: relative;
    overflow: hidden;
    background: linear-gradient(26deg, #0a4a75, #3e799f);
    height: 100vh;

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;
        padding: 2.5em 7em;
        margin: 2.5em 6em;
        height: 37.5em;

        .text {

            .subTitle {
                font-size: 24px;
                background-color: #f2d1007d; // --s-color
                width: fit-content;
                padding: 3px 12px;
                border-radius: 6px;
                font-weight: 500;
            }

            .mainTitle {
                font-size: 42px;
                max-width: 12em;
                margin: 20px 0 30px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.5em;
                max-width: 500px;
                margin-bottom: 20px;
                color: #626262;
            }

            button {
                width: 8em;
                height: 2.5em;
                border: none;
                outline: none;
                font-weight: 500;
                font-size: 17px;
                border-radius: 6px;
                color: #fff;
                background-color: var(--f-color);
                cursor: pointer;

                &:hover {
                    opacity: 0.9;
                }
            }


            ul {
                width: fit-content;
                margin-top: 20px;
                display: flex;
                align-items: center;
                gap: 1.5em;
            
                i {
                    font-size: 23px;
                    transition: var(--transition);

                    &:hover {
                        transform: scale(1.05) rotate(5deg);
                        cursor: pointer;
                    }
                }

                img {
                    width: 2.5em;
                    margin-right: -5px;
                }
            }
        }


        .image {
            position: relative;
            z-index: 1;

            img {
                width: 27em
            }

            .shape {
                background-color: #f9e882;
                width: 22em;
                height: 21em;
                position: absolute;
                bottom: 4px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
                border-top-left-radius: 12em;
                border-top-right-radius: 4em;

                // &::before {
                //     content: "";
                //     border: 3px solid black;
                //     width: 22em;
                //     height: 22em;
                //     position: absolute;
                //     bottom: -25px;
                //     left: 42%;
                //     transform: translateX(-50%);
                //     z-index: -1;
                //     border-top-left-radius: 12em;
                //     border-top-right-radius: 4em;
                // }
            }

            .name {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -2.1em;

                h2 {
                    font-size: 25px;
                    width: fit-content;
                    line-height: 1.5em;
                    position: relative;
                    z-index: 1;
                    color:#fff;
    
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 8px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: linear-gradient(26deg, var(--t-color), var(--s-color));
                        width: 104%;
                        height: 25px;
                        padding: 2px;
                        z-index: -1;
                    }
                }
            }


            .logo {
                position: absolute;
                right: 22em;
                top: 3em;
                width: 11em;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;



                img {
                    width: 8em;
                    position: absolute;
                    top: -9em;
                    left: 8px;
                }
    
            }

        }


    }


    .callUs {
        position: absolute;
        top: 60px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        background-color: #FFF;
        padding: 10px 15px 10px 10px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border: 2px solid #0a4a75;
        transition: var(--transition);

        i {
            margin-right: 10px;
            background: linear-gradient(26deg, #0a4a75, #4c8ab4 , #b3e0ff);
            color: #fff;
            font-size: 22px;
            padding: 10px;
            border-radius: 20px;
        }

        .number {

            h4 {
                font-size: 14px;
                margin-bottom: 2px
            }

            p {

            }
        }

    }
}

@media (max-width: 1200px) {
    .landing .container {
        margin: 2.5em 3em;
        padding: 2.5em 5.5em;
    }

    .landing .container .text .subTitle {
        font-size: 22px;
    }

    .landing .container .text .mainTitle {
        font-size: 32px;
    }

    .landing .container .text p {
        max-width: 420px;
        font-size: 13px
    }

    .landing .container .text button {
        width: 7.5em;
        height: 2.2em;
        font-size: 15px;
    }


    .landing .container .image img {
        width: 24.5em;
    }

    .landing .container .image .shape {
        width: 20.5em;
        height: 19.5em;
    }

    .landing .container .image .logo img {
        top: -10em;
        left: 4.5em;
    }
}

@media (max-width: 950px) {

    .landing {
        height: 145vh;
    }

    .landing .container {
        flex-direction: column;
        text-align: center;
        padding: 2em 5em;
        height: 55em;
    }

    .landing .container .text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .landing .container .text .mainTitle {
        margin: 20px 0 20px;
    }

    .landing .container .image {
        position: absolute;
        bottom: 3em;
    }

    .name h2 {
        font-size: 23px;
    }

    
}

@media (max-width: 780px) {
    
    .landing {
        height: 160vh
    }

    .landing .container {
        height: 55em
    }

    .landing .container .image {
        top: auto;
    }

    .landing .container .image .logo img {
        top: -4em;
        width: 7em
    }
   
}

@media (max-width: 550px) {

    .landing {
        height: 135vh;
    }

    .landing .container {
        padding: 3em 2em;
        margin: 2.5em 1.5em;
        height: 58em;
    }

    .landing .container .text .mainTitle {
        font-size: 31px;
    }

    .landing .container .image img {
        width: 22em;
    }
    .landing .container .image .shape {
        width: 19em;
        height: 18em;
    }

    
    .landing .container .image .name {
        bottom: -1em;
    }
    .landing .container .image .name h2 {
        font-size: 21px;
    }
    .landing .container .image .name h2::after {
        height: 20px;
    }


    .landing .container .image .logo img {
        left: 9.5em;
        width: 5.5em
    }
}



.services {
    overflow: hidden;
    .container {
        padding: 3em 6em;

        .title {
            
            h1 {
                position: absolute;
                text-align: center;
                width: fit-content;
                left: 50%;
                transform: translateX(-50%);

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -8px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: linear-gradient(26deg, var(--t-color), var(--s-color));
                    width: 100%;
                    height: 28px;
                    z-index: -1;
                }
            }
        }
        
        .service {
            display: flex;
            justify-content: space-between;
            margin: 5em 0;

            .text {
                width: 50%;
                
                .title {
                    display: flex;
                    align-items: center;
                    position: relative;

                    h2 {
                        font-size: 3.3em;
                    }

                    h3 {
                        width: fit-content;
                        margin-right: 10px;
                        font-weight: 600;
                    }
                }

                .desc {
                    margin-top: -7px;

                    p {
                        margin-top: 0.8em;
                        max-width: 600px;
                        line-height: 1.5em;
                        font-weight: 300;
                    }

                    ul {
                        list-style: disc;
                        width: 15em;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0.6em 4em;
                        margin-top: 18px;
                        padding-right: 18px;
                        font-weight: 300;

                        li {

                        }
                    }

                    .features {
                        margin-top: 16px;

                        h4 {
                            margin-bottom: -6px
                        }
                    }

                    button {
                        background: linear-gradient(26deg, #0a4a75, #3e799f);
                        color: #FFF;
                        width: 10em;
                        height: 2.7em;
                        border: none;
                        border-radius: 7px;
                        margin-top: 15px;
                        font-weight: 600;

                        &:hover {
                            cursor: pointer;
                            background: linear-gradient(26deg, #1779ba, #3e799f)
                        }
                    }
                }
            }

            .image {
                img {
                    width: 28em;
                    transition: var(--transition);

                    &:hover {
                        transform: scale(1.05) rotate(3deg)
                    }
                }
            }
        }

    
    }
}

@media (max-width: 1300px) {
    .services .container .serviceDivNoti .container .carousel .post img {
        width: 24em;
    }
}

@media (max-width: 1000px) {

    .services .container {
        padding: 3em 4em;
    }

    .services .container .service .text .title {
        font-size: 14px;
    }

    .services .container .service .text .desc p {
        font-size: 15px;
    }

    .services .container .service .image img {
        width: 26em;
    }
    .services .container .service .image img:hover {
        transform: scale(1.01) rotate(-1deg)
    }
}

@media (max-width: 850px) {

    .services .container .title h1 {
        font-size: 25px;
    }

    .services .container .service {
        display: flex;
        justify-content: space-between;
        margin: 5em 0;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .services .container .service .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%
    }

    .services .container .service .text .desc .features {
        margin-top: 20px;
    }

    .services .container .service .text .desc .features ul {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 650px) {

    .services .container .service .text {
        width: 100%;
    }

    .services .container .service .text .title {
        font-size: 12px;
    }
}

.customers {
    overflow: hidden;
    background: linear-gradient(26deg, #0a4a75, #3e799f);
    .container {
        text-align: center;
        padding: 3em 6em 4em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .title {
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;

            .number {
                font-size:  5em;
                position: relative;
                width: fit-content;
                z-index: 1;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 17px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: linear-gradient(26deg, var(--t-color), var(--s-color));
                    width: 110%;
                    height: 25px;
                    z-index: -1;
                }
            }

            p {
                font-size: 20px;
                font-weight: 700;
            }
        }

        .feedbacks {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 6em 2.3em;
            margin-top: 5em;
            width: 100%;

            .feedback {
                position: relative;
                background-color: #FFF;
                padding: 1em;
                width: 30%;
                height: 13em;
                border-radius: 10px;

                .person {
                    margin-top: -72px;
                    
                    img {
                        width: 5.5em;
                        height: 5.5em;
                        border-radius: 50%;
                        border: 6px solid #fff;
                    }
    
                    .name {
                        font-size: 16px;
                    }
                }

                p {
                    margin-top: 35px;
                    line-height: 1.8em;
                    color: #434343;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .customers .container {
        padding: 3em 4em 4em;
    }
}

@media (max-width: 950px) {
    .customers .container .feedbacks {
        gap: 6em;
        flex-direction: column;
        margin-top: 7em;
    }

    .customers .container .feedbacks .feedback {
        width: 65%;
    }

    .customers .container .feedbacks .feedback p {
        font-size: 17px;
        font-weight: 500;
    }
}


@media (max-width: 600px) {

    .customers .container .feedbacks .feedback {
        width: 100%;
    }

}

.contact {
    overflow: hidden;
    .container {
        display: flex;
        justify-content: center;
        padding: 7em;

        .formContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: linear-gradient(35deg, var(--f-color), #0763a0);
            color: #fff;
            padding: 30px 50px;
            border-radius: 5px;

            .title {
                text-align: center;
                h1 {
                    position: relative;
                    z-index: 1;
                    margin-bottom: 20px;
    
                    &::after {
                        content: "";
                        background: linear-gradient(26deg, var(--t-color), var(--s-color));
                        width: 42%;
                        height: 4px;
                        border-radius: 5px;
                        position: absolute;
                        bottom: -12px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                p {

                }
            }

            form {
                display: inherit;
                justify-content: inherit;
                flex-direction: inherit;
                margin-top: 0.6em;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0 2em;

                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 50%;
                    }

                    label {
                        display: block
                    }
                    input {
                        width: 100%
                    }
                }

                label {
                    margin: 15px 0 6px 0;
                    font-size: 15px;
                    font-weight: 400;
                }

                input {
                    height: 3.1em;
                    width: 32em;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    padding-right: 10px;
                }

                textarea {
                    height: 10em;
                    padding: 10px 10px 0 0;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    resize: none;
                }

                button {
                    background: linear-gradient(26deg, var(--t-color), var(--s-color));
                    margin-top: 20px;
                    height: 3em;
                    border-radius: 5px;
                    border: none;
                    color: #fff;
                    font-weight: 800;
                    font-size: 16px;

                    &:hover {
                        cursor: pointer;
                        background: linear-gradient(26deg, #f4a718, #f8d702)
                    }
                }
            }
        }
    }
}

@media (max-width: 580px) {

    .contact .container { padding: 7em 0; }

    .contact .container .formContainer { padding: 20px 35px; }
    
    .contact .container .formContainer .title h1 { font-size: 27px; }

    .contact .container .formContainer .title p { font-size: 14px; }

    .contact .container .formContainer form input {
        height: 2.9em;
        width: 24em;
    }

    .contact .container .formContainer form textarea {  height: 8em; }

    .contact .container .formContainer form button { height: 2.7em; }
}


footer {
    overflow: hidden;
    background-color: var(--f-color);

    .socials {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3em;
        padding: 12px 0;

        li {
            a {
                i {
                    font-size: 30px;
                    color: #fff;
                    border-radius: 50%;
                    width: 1.4em;
                    height: 1.4em;
                    position: relative;
    
                    &::before {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%)
                    }
                }
            }
        }
        
    }

    .copyright {
        text-align: center;
        p {
            color: #fff;
            background: #0b3856;
            padding: 12px 0;
        }
    }

}