* { margin:0; padding:0; box-sizing:border-box; font-family: Alexandria}

:root {
    --f-color: #0A4A75;
    --s-color: #F2D100;
    --t-color: #F2AB24;
    --transition: 0.5s
}


/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: "Alexandria";
    src:
      local("Alexandria"),
      url("../../Fonts/Alexandria-VariableFont_wght.ttf") format("opentype"),
}

/* Classes */
.disNone{display:none}
.disBlock{display:block}
.disFlex{display:flex}

/* Elements */
a{text-decoration:none; color:#000;}
ul{list-style:none}



// For Call Us Notifaction
.callUsOpen{right: -10px}
.callUsClosed{right: -163px}